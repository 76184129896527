<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Pamatdati
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="!displayForm('SendInvoiceByEmailForm')">
        <Button icon="email-alert" tooltip="Nosūtīt rēķinu e-pastā" @click="showForm('SendInvoiceByEmailForm')" />

        <Button icon="briefcase-download" tooltip="Lejuplādēt Rēķina PDF" :tooltipRight="true" @click="downloadPdf" />

        <Button icon="download-folder" tooltip="Lejuplādēt Rēķina XML" :tooltipRight="true" @click="downloadXML" />

        <template v-if="item.allow_download_excel">
            <Button icon="download" tooltip="Lejuplādēt Excel ar kodiem" :tooltipRight="true" @click="downloadExcel" />
        </template>
      </template>
    </template>

    <template v-slot:content>
      
        <template v-if="item">
          <template v-if="!displayForm('SendInvoiceByEmailForm')">
            <ShowInvoiceDetails :item="item" />
          </template>
          
          <template v-if="displayForm('SendInvoiceByEmailForm')">
            <SendInvoiceByEmail :item="item" />
          </template>

          <InvoiceOrderDetails :item="item"/>
          <InvoiceImages :item="item" />
        </template>
            
    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { defineAsyncComponent } from 'vue'

const ItemCard = defineAsyncComponent(() =>
  import('@/components/Components/ItemCard'))
const ShowInvoiceDetails = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/ShowInvoiceDetails'))
const InvoiceOrderDetails = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/InvoiceOrderDetails'))
const InvoiceImages = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/Images/InvoiceImages'))
const SendInvoiceByEmail = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/SendInvoiceByEmail'))

export default {
  name: "InvoiceDetails",
  components: {
    ItemCard,
    ShowInvoiceDetails,
    InvoiceOrderDetails,
    InvoiceImages,
    SendInvoiceByEmail,
  },
  props: ['item'],
  data: () => ({
    showDetails: false,
  }),
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    downloadPdf() {
      const order = {
        ...this.item,
        customer: {
          name: this.item.customer,
        },
        invoice: {
          invoice_uuid: this.item.uuid
        }
      }
      this.$store.dispatch('getOrderInvoicePdf', order)
    },
    downloadExcel() {
        this.$store.dispatch('getOrderItemExcel', this.item)
    },
    downloadXML() {
        this.$store.dispatch('getOrderInvoiceXML', this.item.id)
    },
  }
}
</script>
